import { render, staticRenderFns } from "./medieFacultate.vue?vue&type=template&id=b295559e&scoped=true&"
import script from "./medieFacultate.vue?vue&type=script&lang=js&"
export * from "./medieFacultate.vue?vue&type=script&lang=js&"
import style0 from "./medieFacultate.vue?vue&type=style&index=0&id=b295559e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b295559e",
  null
  
)

export default component.exports